// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import { combineContexts } from "utils/contexts"

const application = Application.start()
// basically we will have multiple contexts, because we will share some controllers between
// admin and frontend, so we can't just require one context ... we need to require multiple
// and combine them into one and send it to stimulus
const context = combineContexts(
  require.context("controllers/application", true, /_controller\.js$/),
  require.context("controllers/shared", true, /_controller\.js$/)
)
application.load(definitionsFromContext(context))
