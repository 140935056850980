import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    this.runnable = this.element.tagName === "FORM"
    this.onFormChange = this._onFormChange.bind(this)
  }

  connect() {
    if (this.runnable) {
      this._checkValidity()
      this.element.addEventListener("change", this.onFormChange)
    }
  }

  disconnect() {
    if (this.runnable) this.element.removeEventListener("change", this.onFormChange)
  }

  _onFormChange() {
    this._checkValidity()
  }

  _checkValidity() {
    const submitElement = this.submitElement
    if (!submitElement) return
    submitElement.toggleAttribute("disabled", !this.element.checkValidity())
  }

  get submitElement() {
    return this.element.querySelector('input[type="submit"]')
  }
}
