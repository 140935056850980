import { Controller } from "stimulus"
import Timer from "easytimer.js"

export default class extends Controller {
  static targets = [ "days", "hours", "minutes", "seconds" ]
  static values = { date:String, feedbackLabel:String }

  initialize() {
    this.reloadCount = 0
    this.onTargetAchieved = this._onTargetAchieved.bind(this)
  }

  connect() {
    this.timer = new Timer()
    this.timer.addEventListener("started", () => {
      setTimeout(() => {
        this.element.classList.add("show")
      }, 1000)
    })
    this.timer.addEventListener("secondsUpdated", () => {
      this.update(this.timer.getTimeValues())
    })
    this.timer.addEventListener("targetAchieved", this.onTargetAchieved)
    this.timer.start({ countdown: true, startValues: { seconds: this.time } })
  }

  disconnect() {
    this.timer.stop()
    this.timer = null
    this.element.classList.remove("show")
  }

  update(values) {
    // basically we need to loop through each timer part and set the value, instead of
    // duplicating this check for each part, we are using an array with the parts and then
    // we loop each part and set the value if the target element exists, for instance, we
    // could for some reason decide to hide the days or the seconds
    // (hide values in the middle doesn't make sense)
    ["Days", "Hours", "Minutes", "Seconds"].forEach((part) => {
      if (this[`has${part}Target`]) this[`${part.toLowerCase()}Target`].innerText = values[part.toLowerCase()]
    })
  }

  _onTargetAchieved() {
    this.feedbackContainer.innerText = this._reloadLabel(5 - this.reloadCount)

    setTimeout(() => {
      this.reloadCount += 1
      if (this.reloadCount <= 5) {
        this._onTargetAchieved()
      } else {
        window.location.reload()
      }
    }, 1000)
  }

  _reloadLabel(timeRemaining) {
    let label
    if (this.feedbackLabelValue !== '') {
      label = this.feedbackLabelValue
    } else {
      label = 'Page will be reloaded in %{seconds} seconds.'
    }

    return label.replace('%{seconds}', timeRemaining)
  }

  get feedbackContainer() {
    const sibling = this.element.nextSibling
    if (sibling.classList.contains('countdown-label')) return sibling
    return null
  }

  get time() {
    return (new Date(this.dateValue) - new Date()) / 1000
  }
}
