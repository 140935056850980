import { Controller } from "stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  static targets = [ "template" ]
  static values = { allowSubmit: Boolean }

  initialize() {
    this.onUploadStart = this._onUploadStart.bind(this)
    this.onUploadComplete = this._onUploadComplete.bind(this)
  }

  connect() {
    this._submitStatus(this.allowSubmitValue)
    this.element.addEventListener('uploader.started', this.onUploadStart)
    this.element.addEventListener('uploader.complete', this.onUploadComplete)
  }

  disconnect() {
    this.element.removeEventListener('uploader.started', this.onUploadStart)
    this.element.removeEventListener('uploader.complete', this.onUploadComplete)
  }

  doConfirm(event) {
    event.preventDefault()
    this.show()
  }

  show() {
    const modal = this.modalElement
    const modalBody = modal.querySelector('.modal-body')
    modalBody.innerHTML = this.templateTarget.innerHTML
    new Modal(modal).show()
  }

  onSubmit(event) {
    if (!this.canSubmit) event.preventDefault()
  }

  _onUploadStart() {
    this._submitStatus(false)
  }

  _onUploadComplete() {
    this._submitStatus(true)
  }

  _submitStatus(canSubmit) {
    this.canSubmit = canSubmit
    this.submitButton.toggleAttribute('disabled', !this.canSubmit)
  }

  get modalElement() {
    return document.querySelector('.modal')
  }

  get submitButton() {
    return this.element.querySelector('input[type="submit"]')
  }
}
