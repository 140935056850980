import { Controller } from "stimulus"
import { Modal } from "bootstrap"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "template" ]

  initialize() {
    this.isLoading = false
    this.onHide = this._onHide.bind(this)
  }

  show(event) {
    event.preventDefault()

    // basically if we are loading anything we don't allow to load any other modal
    if (this.isLoading) return

    const button = event.currentTarget
    // basically we will only load the template target if we aren't dealing with an
    // `A` tag if we are ... a remote request will be done to load the content and only
    // then the modal will be shown
    if (button.tagName === 'A' && button.href !== '') {
      this._loadRemote(button.href)
    } else if (this.hasTemplateTarget) {
      this._showModal(this.templateTarget.innerHTML)
    }
  }

  _loadRemote(location) {
    this.isLoading = true

    Rails.ajax({
      type: "GET",
      dataType: "json",
      url: location,
      success: (response) => {
        if (response.template) this._showModal(response.template)
        this.isLoading = false
      }
    })
  }

  _showModal(content) {
    const modal = this.modalElement
    const modalBody = modal.querySelector('.modal-body')
    modalBody.innerHTML = content
    new Modal(modal, { backdrop: true }).show()
    // we will listen to hide event, because we need to remove the modal content, otherwise
    // some things could happen ... like a video continue playing
    modal.addEventListener('hide.bs.modal', this.onHide)
  }

  _onHide() {
    const modal = this.modalElement
    const modalBody = modal.querySelector('.modal-body')
    modalBody.innerHTML = ''

    // now we remove because we don't need the event anymore, if another modal is open
    // another event will be attached
    this.modalElement.removeEventListener('hide.bs.modal', this.onHide)
  }

  get modalElement() {
    return document.querySelector('.modal')
  }
}
