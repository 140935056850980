import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "collapsible" ]
  static values = { opened: String, closed: String }

  initialize() {
    this.originalButtonLabel = this.collapseButton.innerHTML
    this.labelChanged = false
    this.onShow = this._onShow.bind(this)
    this.onHide = this._onHide.bind(this)
  }

  connect() {
    this.collapsibleTarget.addEventListener("hide.bs.collapse", this.onHide)
    this.collapsibleTarget.addEventListener("show.bs.collapse", this.onShow)
  }

  disconnect() {
    this.collapsibleTarget.removeEventListener("hide.bs.collapse", this.onHide)
    this.collapsibleTarget.removeEventListener("show.bs.collapse", this.onShow)
  }

  _onShow() {
    this.element.classList.add("open")
    this.collapsibleTarget.setAttribute("tabindex", 0)

    if (this.openedValue !== "") {
      this.collapseButton.innerHTML = this.openedValue
      this.labelChanged = true
    }
  }

  _onHide() {
    this.element.classList.remove("open")
    this.collapsibleTarget.removeAttribute("tabindex")

    if (!this.labelChanged) return
    const buttonLabel = this.closedValue !== "" ? this.closedValue : this.originalButtonLabel
    this.collapseButton.innerHTML = buttonLabel
  }

  get collapseButton() {
    return this.element.querySelector('[data-bs-toggle="collapse"]')
  }
}
