import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "button", "stage" ]
  static values = { buttonLabel: String, loadingLabel: String, delegateInsert: Boolean }

  initialize() {
    if (this.hasButtonTarget) {
      this.url = new URL(this.buttonTarget.href)
      this.searchParams = this.url.searchParams
      this.last = false
      this.buttonTarget.innerText = this.buttonLabel
    }
  }

  getPage(event) {
    event.preventDefault()
    if (!this.last) this._loadContent()
  }

  _loadContent() {
    this._displayLoading()

    Rails.ajax({
      type: "GET",
      dataType: "json",
      url: this.url,
      success: (response) => {
        this._update(response.next)

        if (this.delegateInsertValue) {
          this.stageTarget.dispatchEvent(new CustomEvent("paginator.update", { detail: { template: response.template } }))
        } else {
          this.stageTarget.innerHTML += response.template
        }
      }
    })
  }

  _update(page) {
    if (page) {
      // OK we will page our `searchParams` to reflect the new page that should be
      // loaded, so we will need to remove the param page from the `searchParams` and then
      // add the value for the new page
      this.searchParams.delete("page")
      this.searchParams.append("page", page)
      this.buttonTarget.href = this.url
      this._revertLoading()
    } else {
      // OK, we don't have more pages to show, so we will just remove the button from
      // the page, and set flag `last` to true, that will prevent any call to the `_loadContent`
      // page unless we really have more pages to show
      this.last = true
      this.buttonTarget.remove()
    }
  }

  _displayLoading() {
    this.buttonTarget.innerText = this.loadingLabel
    this.buttonTarget.classList.add('disabled')
  }

  _revertLoading() {
    this.buttonTarget.innerText = this.buttonLabel
    this.buttonTarget.classList.remove('disabled')
  }

  get buttonLabel() {
    return this.buttonLabelValue !== '' ? this.buttonLabelValue : 'Load More'
  }

  get loadingLabel() {
    return this.loadingLabelValue !== '' ? this.loadingLabelValue : 'Loading ...'
  }
}
