const { concat, uniq } = require("lodash")

// since webpack documentation don't have any hint about it we are using the method found in
// https://survivejs.com/webpack/techniques/dynamic-loading/#combining-multiple-require-contexts
// that will allow us to combine multiple contexts into one

export const combineContexts = (...contexts) => {
  function webpackContext(req) {
    // find the first match and execute
    const matches = contexts.map((context) => context.keys().indexOf(req) >= 0 && context).filter((a) => a)

    return matches[0] && matches[0](req)
  }

  webpackContext.keys = () =>
    uniq(
      concat.apply(
        null,
        contexts.map((context) => context.keys())
      )
    )

  return webpackContext
}
