import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "progress", "phase" ]
  static values = { phase:String }

  connect() {
    if (this.hasProgressTarget) this._setProgress()
    this._highlightPhase()
    this._highlightPastPhases()
  }

  _setProgress() {
    this._progressAriaValues()
    this._showProgress()
  }

  _progressAriaValues() {
    const progressElement = this.progressTarget
    const totalPhases = this.phaseTargets.length
    const currentPhase = this.phaseTargets.findIndex((element) => {
      return element.dataset.phase === this.phaseValue
    })

    progressElement.setAttribute('aria-valuemax', totalPhases)
    progressElement.setAttribute('aria-valuenow', currentPhase >= 0 ? (currentPhase + 1) : 0)
  }

  _highlightPhase() {
    const currentPhase = this.selectedPhase
    if (currentPhase) currentPhase.classList.add('current')
  }

  _highlightPastPhases() {
    const index = this.currentIndex
    if (index >= 0) {
      const pastPhases = this.phaseTargets.slice(0, this.currentIndex)
      Array.from(pastPhases).forEach((phase) => phase.classList.add('done'))
    }
  }

  _showProgress() {
    if (this.currentIndex < 0) return

    const currentPhase = this.selectedPhase
    const siblingPhase = currentPhase.nextSibling

    // we will use the sibling element because it's easier to do the math, also if we don't
    // have any sibling, we are on the last phase of the competition
    if (siblingPhase) {
      const parentBound = siblingPhase.parentNode.getBoundingClientRect()
      const siblingBound = siblingPhase.getBoundingClientRect()
      const percentage = ((siblingBound.left - parentBound.left) / parentBound.width * 100).toFixed(2)
      this.progressTarget.style.width = `${percentage}%`
    } else {
      this.progressTarget.style.width = "100%"
    }
  }

  get selectedPhase() {
    return this.phaseTargets.find((element) => element.dataset.phase === this.phaseValue)
  }

  get currentIndex() {
    return this.phaseTargets.findIndex((element) => element.dataset.phase === this.phaseValue)
  }
}
