import { Controller } from "stimulus"
import Glide from "@glidejs/glide"

export default class extends Controller {
  static targets = [ "progress" ]
  static values = { progress: Boolean, slideInterval: Number, peek: Number }

  initialize() {
    this.onProgress = this._showProgress.bind(this)
    this.onRun = this._onRun.bind(this)
    this.onIntersection = this._onIntersection.bind(this)
    this.timing = this.slideInterval
    this.intervalID = null
    this.elapsed = 0
  }

  connect() {
    this.slider = new Glide(
      this.element,
      {
        peek: { before: 0, after: this.totalSlides > 1 ? this.peekValue : 0 },
        gap: 0,
        dragThreshold: 120,
        rewind: true,
        breakpoints: {
          1024: {
            peek: { before: 0, after: 0 }
          }
        }
      }
    )

    if (this.progressValue && this.totalSlides > 1) {
      this._createProgress()
      this.slider.on("mount.after", this.onProgress)
      this.slider.on("run", this.onProgress)
      // OK, we will setup an `IntersectionObserver` in order to reset the autoplay whenever
      // the user leaves the slide section, if the user returns we will start the counter from
      // the beginning
      this.observer = new IntersectionObserver(this.onIntersection, { root: null, rootMargin: "0px" })
      this.observer.observe(this.element)
    }

    if (this.haveControls) {
      this.slider.on("run.after", this.onRun)
      // disabled the left arrow
      this.previousButton.classList.add("disabled")
    }

    // if we only have 1 slide, we will just remove any bullet container that could be
    // available, otherwise it will just look weird
    if (this.totalSlides === 1) {
      const bulletContainer = this.element.querySelector('[data-glide-el="controls[nav]"]')
      if (bulletContainer) bulletContainer.remove()

      // additionally we also need to check if there is any directional button, if we
      // have any we will remove it
      const directionalButtons = this.element.querySelectorAll('[data-glide-dir]')
      if (directionalButtons.length > 0) directionalButtons.forEach((element) => element.remove())
    }

    this.slider.mount()
  }

  _onRun() {
    const totalSlides = parseInt(this.element.dataset.slides)
    const slide = this.slider.index + 1

    this.previousButton.classList.toggle("disabled", slide === 1)
    this.nextButton.classList.toggle("disabled", slide === totalSlides)
  }

  _onIntersection(entries) {
    if (this.progressValue) {
      if (entries[0].isIntersecting) {
        this._showProgress()
      } else {
        clearInterval(this.intervalID)
      }
    }
  }

  _createProgress() {
    this.element.insertAdjacentHTML(
      "afterbegin",
      `<div class="glide__progress" data-glide-target="progress"></div>`
    )
  }

  _showProgress() {
    this._clearProgress()
    this.intervalID = setInterval(() => {
      const current = this.elapsed / this.timing * 100
      if (current <= 100) {
        this.progressTarget.style.width = `${current}%`
        this.elapsed += 10
      } else {
        clearInterval(this.intervalID)
        this.slider.go(">")
      }
    }, 10)
  }

  _clearProgress() {
    if (this.intervalID) clearInterval(this.intervalID)
    this.elapsed = 0
  }

  get totalSlides() {
    return parseInt(this.element.dataset.slides)
  }

  get haveControls() {
    return this.element.querySelector(".glide__controls") !== null
  }

  get slideInterval() {
    return this.slideIntervalValue > 0 ? this.slideIntervalValue : 10000
  }

  get previousButton() {
    return this.element.querySelector('[data-glide-dir="<"]')
  }

  get nextButton() {
    return this.element.querySelector('[data-glide-dir=">"]')
  }
}
