import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "tabs", "stage", "submission", "tag" ]

  doFilter(event) {
    if (this._activateButton(event.currentTarget)) this._filter()
  }

  doRefine() {
    this._filter()
  }

  _filter() {
    this._hideSubmissions()
    let submissions = this.submissionTargets
    submissions = this._filterByCategory(submissions)
    submissions = this._filterByTag(submissions)
    submissions.forEach((element) => element.classList.remove(this.hiddenClass))
    this._noResultsCheck()
    this.element.dispatchEvent(new CustomEvent("filter.update", { bubbles: true }))
  }

  _filterByCategory(submissions) {
    const category = this.category
    if (category === "all") return submissions
    return submissions.filter((element) => element.dataset.category === category)
  }

  _filterByTag(submissions) {
    const tags = this.tagsList
    if (!tags || tags.includes("all") || tags.length === 0) return submissions
    return submissions.filter((element) => tags.includes(element.dataset.tag))
  }

  _noResultsCheck() {
    if (this.hasStageTarget) {
      const visible = this.submissionTargets.filter((element) => !element.classList.contains(this.hiddenClass)).length
      this.stageTarget.classList.toggle("is-empty", visible === 0)
    }
  }

  _activateButton(button) {
    if (button.classList.contains("active")) return false

    const selectedFilter = this.tabsTarget.querySelector(".active")
    if (selectedFilter) selectedFilter.classList.remove("active")
    button.classList.add("active")
    return true
  }

  _hideSubmissions() {
    this.submissionTargets.forEach((element) => element.classList.add(this.hiddenClass))
  }

  _showSubmissions() {
    this.submissionTargets.forEach((element) => element.classList.remove(this.hiddenClass))
  }

  get category() {
    return this.tabsTarget.querySelector(".active[data-option]").dataset.option
  }

  get tagsList() {
    if (!this.hasTagTarget) return null
    return this.tagTargets.filter((input) => input.checked).map((input) => input.value)
  }

  get hiddenClass() {
    return "d-none"
  }
}
