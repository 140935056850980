import { Controller } from "stimulus"

export default class extends Controller {
  static values = { timeout: Number }

  initialize() {
    this.timeoutID = null
  }

  execute() {
    if (this.element.tagName !== 'FORM') {
      console.error('This controller will only work if assigned to a <FORM> tag')
      return
    }

    if (this.timeoutID) clearTimeout(this.timeoutID)

    this.timeoutID = setTimeout(() => {
      this.element.submit()
    }, this.timeout)
  }

  get timeout() {
    return this.timeoutValue > 0 ? this.timeoutValue : 250
  }
}
