import { Controller } from "stimulus"
import Choices from "choices.js"

export default class extends Controller {
  connect() {
    this.choices = new Choices(this.selectElement, {...this.elementOptions, ...this.defaultOptions})
  }

  disconnect() {
    this.choices.destroy()
  }

  get defaultOptions() {
    return {
      classNames: {
        containerInner: this.selectElement.className,
        input: 'form-control',
        inputCloned: 'form-control-sm',
        listDropdown: 'dropdown-menu',
        itemChoice: 'dropdown-item',
        activeState: 'show',
        selectedState: 'active',
      },
      shouldSort: false
    }
  }

  get selectElement() {
    // first we will look for a select element, if we don't find one, we will move to
    // an input type text as source for our Choices.js element
    const selectElement = this.element.querySelector('select')
    if (selectElement) return selectElement

    return this.element.querySelector('input[type="text"]')
  }

  get elementOptions() {
    return this.element.dataset.choices ? JSON.parse(this.element.dataset.choices) : {}
  }
}
