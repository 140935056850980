import { Controller } from "stimulus"
import { DirectUpload } from "@rails/activestorage"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "input", "feedback", "template" ]
  static values = { maxSize: Number, minimumHeight: Number, minimumWidth: Number, completeUrl: String, invalidType: String, invalidSize: String, invalidDimension: String, delegateFeedback: Boolean }

  initialize() {
    this.onInputDragOver = this._onInputDragOver.bind(this)
    this.onInputDragLeave = this._onInputDragLeave.bind(this)
    this.onWindowDragDrop = this._onWindowDragDrop.bind(this)
    this.title = document.querySelector('title').innerText
    this.upload = null
  }

  connect() {
    this.inputTarget.addEventListener('dragover', this.onInputDragOver)
    this.inputTarget.addEventListener('dragleave', this.onInputDragLeave)
    this.inputTarget.addEventListener('drop', this.onInputDragLeave)
    window.addEventListener('dragover', this.onWindowDragDrop)
    window.addEventListener('drop', this.onWindowDragDrop)
  }

  disconnect() {
    this.inputTarget.removeEventListener('dragover', this.onInputDragOver)
    this.inputTarget.removeEventListener('dragleave', this.onInputDragLeave)
    this.inputTarget.removeEventListener('drop', this.onInputDragLeave)
    window.removeEventListener('dragover', this.onWindowDragDrop)
    window.removeEventListener('drop', this.onWindowDragDrop)
  }

  start(event) {
    this.feedbackTarget.innerHTML = ""

    const file = event.target.files[0]
    const previewElement = this._previewFile()

    if (!this._isValidFile(file)) {
      this._showError(this.invalidFileType)
    } else if (!this._isValidFileSize(file)) {
      this._showError(this.invalidFileSize)
    } else if (this.validateDimensions) {
      this._validateDimensions(file, previewElement)
    } else {
      this.inputTarget.setAttribute('disabled', true)
      this._clearError()
      this._accept(file)
    }
  }

  _validateDimensions(file, preview) {
    // OK, basically depending on the `tagName` that we are handling we will have different
    // approaches, because `VIDEO` have different listeners in order to get it dimensions
    if (preview.tagName === 'VIDEO') {
      // for video we will use `loadedmetadata` event that will allow us to check if dimensions
      // of the video, we should take in account that this only works because below we are
      // creating a video blob to play the video
      preview.addEventListener('loadedmetadata', (event) => {
        const element = event.currentTarget
        if (element.videoWidth < this.minimumWidthValue
            || element.videoHeight < this.minimumHeightValue) {
          this._showError(this.invalidDimension)
        } else {
          this._clearError()
          this._accept(file)
        }
      })
    } else {
      preview.addEventListener('load', (event) => {
        const element = event.currentTarget
        if (element.naturalWidth < this.minimumWidthValue
            || element.naturalHeight < this.minimumHeightValue) {
          this._showError(this.invalidDimension)
        } else {
          this._clearError()
          this._accept(file)
        }
      })
    }
  }

  _accept(file) {
    this.feedbackTarget.insertAdjacentElement('afterbegin', this._createProgressElement())

    this.upload = new DirectUpload(file, this.inputTarget.dataset.directUploadUrl, this)
    this.upload.create((error, blob) => {
      if (error) {
        this._showError(error)
      } else {
        if (this.completeUrlValue !== "") {
          this.onComplete(blob)
        } else {
          const hiddenField = document.createElement("input")
          hiddenField.setAttribute("type", "hidden")
          hiddenField.setAttribute("value", blob.signed_id)
          hiddenField.name = this.inputTarget.name
          this.element.closest("form").appendChild(hiddenField)
        }
      }
    })
    this.element.dispatchEvent(new CustomEvent("uploader.started", { detail: file, bubbles: true }))
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", event => this.directUploadDidProgress(event))
  }

  directUploadDidProgress(event) {
    const current = (event.loaded / event.total * 100).toFixed(0)
    const progressBar = this.progressElement.querySelector('[role="progressbar"]')
    if (progressBar) {
      progressBar.setAttribute("aria-valuenow", current)
      progressBar.style.width = `${current}%`
    }

    const progressValue = this.progressElement.querySelector("output")
    if (progressValue) progressValue.innerText = `${current}%`

    const previewElement = this.feedbackTarget.querySelector('.upload-preview')
    if (previewElement) previewElement.style.opacity = `${event.loaded / event.total}`

    this._updateTabProgress(current)
  }

  onComplete(blob) {
    Rails.ajax({
      type: "POST",
      url: this.completeUrlValue,
      data: `submission[file]=${blob.signed_id}`,
      dataType: "json",
      success: (response) => {
        if (this.delegateFeedbackValue) {
          this.element.dispatchEvent(new CustomEvent("uploader.complete", { detail: response, bubbles: true }))
        } else {
          if (response.feedback) this._showFeedback(response.feedback)
          if (response.status === "processing") this.inputTarget.setAttribute("disabled", true)
        }

        this._removeProgressElement()
        this._resetTabProgress()
      }
    })
  }

  _previewFile() {
    const file = this.inputTarget.files[0]
    const type = file.type.split("/")[0]
    let preview

    if (type === "image") {
      preview = document.createElement("img")
      preview.setAttribute("alt", "")
      preview.src = URL.createObjectURL(file)
    } else if (type === "video") {
      preview = document.createElement("video")
      preview.setAttribute("controls", true)
      preview.style.opacity = "0"

      const reader = new FileReader()
      reader.readAsArrayBuffer(file)
      reader.onload = (e) => {
        const buffer = e.target.result
        const videoBlob = new Blob([new Uint8Array(buffer)], { type: type })
        const url = URL.createObjectURL(videoBlob)
        preview.src = url
      }
    }

    preview.classList.add('upload-preview')
    this.feedbackTarget.appendChild(preview)

    return preview
  }

  _isValidFile(file) {
    return this.acceptedFiles.includes(file.type)
  }

  _isValidFileSize(file) {
    if (this.maxSizeValue === 0) return true
    return this.maxSizeValue > this._fileSizeToMB(file.size)
  }

  _showFeedback(message) {
    const feedbackElement = document.createElement("div")
    feedbackElement.classList.add("du-feedback")
    feedbackElement.innerHTML = message
    this.feedbackTarget.appendChild(feedbackElement)
  }

  _clearError() {
    this.inputTarget.setCustomValidity('')
    this.inputTarget.parentNode.dataset.message = ''
  }

  _showError(message) {
    this.inputTarget.value = null
    this.inputTarget.setCustomValidity(message)

    const parentNode = this.inputTarget.parentNode
    parentNode.classList.add("was-validated")
    parentNode.dataset.message = message
  }

  _createProgressElement() {
    let element

    if (this.hasTemplateTarget) {
      element = this.templateTarget.content.cloneNode(true).firstChild
    } else {
      element = document.createElement("div")
      element.classList.add("du-progress-container")
      element.innerHTML = `
        <div class="du-progress">
          <div class="du-progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <output class="du-progress-value"></output>
      `
    }

    return element
  }

  _updateTabProgress(progress) {
    const titleElement = document.querySelector('title')
    titleElement.innerText = `${progress}% Uploaded | ${this.title}`
  }

  _resetTabProgress() {
    document.querySelector('title').innerText = this.title
  }

  _removeProgressElement() {
    const progressElement = this.feedbackTarget.querySelector('.upload-progress')
    if (progressElement) progressElement.remove()
  }

  _fileSizeToMB(bytes) {
    return (bytes / (1024*1024)).toFixed(2)
  }

  _onInputDragOver() {
    this.inputTarget.classList.add('is-hover')
  }

  _onInputDragLeave() {
    this.inputTarget.classList.remove('is-hover')
  }

  _onWindowDragDrop(event) {
    if (event.target.nodeName !== 'INPUT') event.preventDefault()
  }

  get validateDimensions() {
    return this.minimumHeightValue > 0 || this.minimumWidthValue > 0
  }

  get acceptedFiles() {
    return this.inputTarget.accept.split(",")
  }

  get invalidFileType() {
    return this.invalidTypeValue !== "" ? this.invalidTypeValue : "File type not allowed"
  }

  get invalidFileSize() {
    return this.invalidSizeValue !== "" ? this.invalidSizeValue : "File is too big"
  }

  get invalidDimension() {
    return this.invalidDimensionValue !== "" ? this.invalidDimensionValue : "File doesn't comply with required dimensions"
  }

  get progressElement() {
    return this.element.querySelector(".upload-progress")
  }
}
