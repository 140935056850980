import { Controller } from "stimulus"
import Macy from "macy"

export default class extends Controller {
  static targets = [ "grid" ]
  static values = { columns: Number }

  initialize() {
    this.onContentUpdate = this._onContentUpdate.bind(this)
    this.onFilterUpdate = this._onFilterUpdate.bind(this)
  }

  connect() {
    this.gridTarget.classList.add("loaded")
    this.macy = Macy({
      container: this.gridTarget,
      margin: 20,
      columns: this.columnsValue > 0 ? this.columnsValue : 4,
      breakAt: this._breakpoints()
    })
    this.element.addEventListener("paginator.update", this.onContentUpdate)
    this.element.addEventListener("filter.update", this.onFilterUpdate)
  }

  disconnect() {
    this.macy.remove()
    this.element.removeEventListener("paginator.update", this.onContentUpdate)
    this.element.removeEventListener("filter.update", this.onFilterUpdate)
  }

  _breakpoints() {
    let breakpoints = this.data.get("breakpoints")
    if (!breakpoints) return { 1024: 2, 960: { margin: 10, columns: 2 } }
    return JSON.parse(breakpoints)
  }

  _onContentUpdate(event) {
    const dom = document.createElement("div")
    dom.innerHTML = event.detail.template
    dom.childNodes.forEach((node) => this.gridTarget.append(node))
    this.macy.reInit()
    this.macy.runOnImageLoad(() => this.macy.recalculate(true, true))
  }

  _onFilterUpdate() {
    this.macy.reInit()
    this.macy.runOnImageLoad(() => this.macy.recalculate(true, true))
  }
}
